import AEM from 'base/js/aem';
import { createApp } from 'vue';
// import Cart from './Cart.vue';
import CartIcon from './CartIcon.vue';
import { cookieSync } from './cookieSync.js';

class XilinxCart extends AEM.Component {
    async init() {
        await cookieSync(this.props.xilinxCookieUrl, this.props.amdCookieUrl);
        this.showShoppingCart();
    }

    showShoppingCart() {
        const component = this.element;
        const target = component.querySelector('#simple-cart-placeholder');
        const checkoutServer = window.location.hostname.includes('amd.com') ? this.props.checkoutServer : '';
        const labels = {
            unitPrice: this.props.labelUnitPrice,
            remove: this.props.labelRemove,
            checkout: this.props.labelCheckout,
            quantity: this.props.labelQuantity,
            title: this.props.labelTitle,
            error: this.props.labelError,
            emptyTitle: this.props.labelEmptyTitle,
            emptyText1: this.props.labelEmptyText1,
            emptyText2: this.props.labelEmptyText2,
            emptyText3: this.props.labelEmptyText3,
            tax: this.props.labelTax,
            calculated: this.props.labelCalculated,
            subtotal: this.props.labelSubtotal,
            shipping: this.props.labelShipping,
            bcProducts: this.props.labelBcProducts,
            omsProducts: this.props.labelOmsProducts,
            itemsInCart: this.props.labelItemsInCart,
            labelQty: this.props.labelQty
        };
        const images = {
            bc: this.props.defaultBcImage,
            oms: this.props.defaultOmsImage,
            empty: this.props.emptyCartImage,
            default: this.props.defaultImage
        };

        const newCartComponent = createApp(CartIcon);
        newCartComponent.provide('cookiePath', this.props.cookiePath);
        newCartComponent.provide('cookieName', this.props.cookieName);
        newCartComponent.provide('checkoutPath', checkoutServer + this.props.checkoutPath);
        newCartComponent.provide('server', this.props.server);
        newCartComponent.provide('labels', labels);
        newCartComponent.provide('images', images);
        newCartComponent.mount(target);
    }
}

export { XilinxCart };
