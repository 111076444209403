const NECESSARY = 'C0001';
const PERFORMANCE = 'C0002';
const FUNCTIONAL = 'C0003';
const TARGETING = 'C0004';
const SOCIAL = 'C0005';

/**
 * @function getCookieConsent - Gets cookie groups the user has consented to.
 * @returns { String } - Cookie groups separated by commas.
 */
function getCookieConsent() {
	const cookieOptanonConsent = getCookie('OptanonConsent')
		.split('&')
		.reduce((res, c) => {
			const [key, val] = c.trim().split('=').map(decodeURIComponent)
			try {
				return Object.assign(res, { [key]: JSON.parse(val) })
			} catch (e) {
				return Object.assign(res, { [key]: val })
			}
		}, {});
	let groupsArray = [];
	if(cookieOptanonConsent['groups']){
		cookieOptanonConsent['groups'].split(',').map(c => {
			let row = c.split(':');
			if (row[1] == '1') {
				groupsArray.push(row[0]);
			}
		});
	}
	return groupsArray.join(',');
}


/**
 * Get a cookie by key
 */
function getCookie (name) {
	let nameEQ = name + "=";
	let ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		let c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return '';
}

/**
	 * Check whether the cookies user have consented to match necessary groups.
	 * @param {array} groupsToMatch
	 * @returns {boolean}
	 */
function consentCheck(groupsToMatch) {
	const consentedCookies = getCookieConsent().split(',');
	return groupsToMatch.every(element => consentedCookies.includes(element));
}

export { consentCheck, getCookie, NECESSARY, PERFORMANCE, TARGETING, FUNCTIONAL, SOCIAL };
