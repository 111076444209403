function pushToGoogleDataLayer(eventType, payloadName, payload) {
    window.dataLayer = window.dataLayer || [];

  	// JSON object
    const gaPayload = {
	    event: eventType,
    };
    gaPayload[payloadName] = payload;
    window.dataLayer.push(gaPayload);
}

export { pushToGoogleDataLayer }
