import AEM from 'base/js/aem';
import { createApp } from 'vue';
import LocationsTemplate from './Locations.vue';

class Locations extends AEM.Component {
    init() {
        this.showLocations();
    }
    showLocations() {
        const locationsComponent = createApp(LocationsTemplate);
        const locationPlaceHolder = this.element.querySelector('[data-locations-json]');
        if (locationPlaceHolder) {
            const locationsData = locationPlaceHolder.dataset.locationsJson;
            locationsComponent.provide('locations', locationsData);
            locationsComponent.mount(locationPlaceHolder);
        }
    }
}

export { Locations };
