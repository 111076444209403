class Component {
    constructor(element) {
        this.element = element;
        this.props = this.element.dataset;
        this.init();
    }
}

function registerComponent(selector, ComponentClass, context = document) {
    let elements;
    if (NodeList.prototype.isPrototypeOf(selector)) {
        elements = selector;
    } else if (selector instanceof HTMLElement) {
        elements = [ selector ];
    } else if (typeof selector === 'string') {
        elements = context.querySelectorAll(selector);
    }
    elements.forEach(element => {
        new ComponentClass(element);
    });
}

export default {
    registerComponent: registerComponent,
    Component: Component
};
