import AEM from 'base/js/aem';
import { getCountry } from 'base/js/templates/general/getCountry.js';

class CountryPromo extends AEM.Component {
    init() {
        this.initCountryPromo();
    }
    initCountryPromo() {
        const el = this.element;
        const country = getCountry();
        const promos = el.querySelectorAll('.countrypromo');
        const countries = [];
        // Find available countries.
        promos.forEach(promo => countries.push(...promo.dataset.country.split(',')));

        if (countries.includes(country)) {
            // Get a single promo that matches the country.
            const [ ourPromo ] = Array.from(promos).filter(e =>
                e.dataset.country.split(',').includes(country)
            );
            const clonedPromo = ourPromo.content.cloneNode(true);
            el.appendChild(clonedPromo);
        } else {
            const defaultPromo = el.querySelector('.defaultpromo');
            if (defaultPromo) {
                const defaultContent = defaultPromo.content.cloneNode(true);
                el.appendChild(defaultContent);
            }
        }
    }
}

export { CountryPromo };
