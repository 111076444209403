class MarketoInstanceManager {
	static instance;
	constructor(instanceBaseURLs) {
		this.instances = new Map();

		/* evergreen browsers can use an unthrown Error, IE 11 needs throw */
		const genStack = () => {
			let tempError = new Error(),
				stack = tempError.stack;

			if (stack) {
				return stack;
			} else {
				try {
					throw tempError;
				} catch (error) {
					return error.stack;
				}
			}
		};

		/* no one said finding the source URL wouldn't be sloppy, but it works */
		const getInstanceURLFromStack = (stack) => {
			return instanceBaseURLs.filter((url) => {
				return stack.indexOf(url) != -1;
			})[0];
		};

		Object.defineProperty(window, "MktoForms2", {
			configurable: true,
			get: () => {
				let stack = genStack(),
					instanceKey = getInstanceURLFromStack(stack);

				return this.instances.get(instanceKey);
			},
			set: (library) => {
				let stack = genStack(),
					instanceKey = getInstanceURLFromStack(stack);

				this.instances.set(instanceKey, library);
			},
		});
	}

	static async mktoInitialize() {
		if (!MarketoInstanceManager.instance) {
			const marketoForms = [...document.querySelectorAll('[data-component="cmp-marketoform"]')];
			const domains = new Set(marketoForms.filter(form => form.dataset.marketoFormDomain).map(form => `//${form.dataset.marketoFormDomain}`.toLowerCase()));
			MarketoInstanceManager.instance = new MarketoInstanceManager(Array.from(domains));
		}

		// Wait for the instance to be initialized before returning it
		return MarketoInstanceManager.instance;
	}


}

const mktoSingleton = MarketoInstanceManager.mktoInitialize();

async function initMktoSingleton() {
	const instancesMap = await mktoSingleton;
	return instancesMap;
}

async function getMktoInstancesMap() {
	const instancesMap = await initMktoSingleton();
	return instancesMap.instances;
}

export {
	initMktoSingleton,
	getMktoInstancesMap
};