/**
 * Pure JS method of document ready.
 * @module ready
 * */

const TICK = 1;

function ready(fn) {
    // Check whether the DOM is already ready.
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        // Call function on next available tick.
        setTimeout(fn, TICK);
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

export default {
    ready: ready
};
