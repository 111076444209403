import AEM from 'base/js/aem';
import { onIntersect } from 'base/js/templates/general/onIntersect.js';

class Video extends AEM.Component {
    init() {
        this.onEnter = () => {
            this.element.play();
        };

        this.onExit = () => {
            return;
        };
        if (this.element.classList.contains('video-replay-scroll')) {
            onIntersect(this.element, this.onEnter, this.onExit, false, { threshold: 0.7 });
        }
    }
}

export { Video };
