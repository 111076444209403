import AEM from 'base/js/aem';
import { loadScript } from 'base/js/templates/general/loadScript';

class LoadScripts extends AEM.Component {
    init() {
        const scripts = this.props.scripts;
        if (scripts){
            const jsArray = scripts.split(',').filter(script => script.endsWith('.js'));
            loadScript(jsArray);
        }
    }
}

export { LoadScripts };
