import AEM from 'base/js/aem';
import { createApp } from 'vue';
import EventsTemplate from './Events.vue';

class Events extends AEM.Component {
    init() {
        this.showEvents();
    }
    showEvents() {
        const eventsComponent = createApp(EventsTemplate);
        const eventPlaceHolder = this.element.querySelector('[data-events-json]');
        const eventDetailsLabel = this.props.cmpEventDetails;
        if (eventPlaceHolder) {
            const eventsData = eventPlaceHolder.dataset.eventsJson;
            eventsComponent.provide('events', eventsData);
            eventsComponent.provide('eventDetails', eventDetailsLabel);
            eventsComponent.mount(eventPlaceHolder);
        }
    }
}

export { Events };
