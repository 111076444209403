export async function cookieSync(xilinxCookieUrl, amdCookieUrl) {
    function loadIframe(src) {
        return new Promise((resolve, reject) => {
            const iframe = document.createElement('iframe');
            iframe.onload = () => resolve(iframe);
            iframe.onerror = reject;
            iframe.src = src;
            iframe.style.display = 'none';
            iframe.title = 'Intentionally blank';
            iframe.ariaHidden = 'true';
            document.body.append(iframe);
        });
    }
    let cookiePageUrl = amdCookieUrl;
    let originDomain = 'amd.com';
    if (!document.domain.includes('xilinx.com') && !document.domain.includes('localhost')) {
        cookiePageUrl = xilinxCookieUrl;
        originDomain = 'xilinx.com';
    }
    window.addEventListener('message', event => {
        if (event.origin.includes(originDomain)) {
            const message = event.data;
            const keys = Object.keys(message);
            keys.forEach(key => {
                if (message[key] !== '') {
                    sessionStorage.setItem(key, message[key]);
                }
            });
        }
    });
    await loadIframe(cookiePageUrl);
}
