<template>
    <template v-if="consent">
        <iframe v-if="showVideo" loading="lazy" class="yt-video-iframe" v-bind:src="videoUrl" frameborder="0"
            allowfullscreen="" allow="autoplay; fullscreen" aria-label="YouTube Video">
        </iframe>
        <template v-else>
            <button type="button" ref="scrollRef" class="lty-playbtn" @click="show">
                <span class="lyt-visually-hidden">
                    {{ playLabel }}
                </span>
            </button>
        </template>
    </template>
    <div v-else class="yt-ot-overlay">
      <a class="yt-ot-direct-link" target="_blank" :href="directVideoUrl"><i class="fa fa-brands fa-youtube"></i>
        {{ watchOnYouTube }}
      </a>
      <div class="yt-ot-content">
        <div class="yt-ot-consent">
          <p v-html="cookieLabel"></p>
        </div>
        <div class="yt-button-row">
          <button class="yt-ot-settings" @click="openCookieSettings">{{ cookieSettingsButton }}</button>
        </div>
      </div>

    </div>
</template>
<script setup>
import { inject, ref, onMounted } from 'vue'
import { consentCheck, PERFORMANCE, TARGETING, FUNCTIONAL } from 'base/js/templates/general/consentCheck.js';
import { onIntersect } from 'base/js/templates/general/onIntersect.js';

const consent = ref(false);
const getConsent = () => consent.value = consentCheck([PERFORMANCE, TARGETING, FUNCTIONAL]);
getConsent();
window.addEventListener('cookiesUpdated', getConsent);
let showVideo = ref(false);
const playLabel = inject('playLabel');
const cookieLabel = inject('cookieLabel');
const videoUrl = inject('videoUrl');
const directVideoUrl = inject('directVideoUrl');
const cookieSettingsButton = inject('cookieSettingsButton');
const watchOnYouTube = inject('watchOnYouTube');
// This ref refers to the viewport.
const observer = ref(null);
// This ref refers to whatever we have scrollRef on.
const scrollRef = ref(null);
// What do we do when scrollRef intersects with the observer?
const onEnter = () => {
    // We show the video!
    show();
}
function show() {
    showVideo.value = true;
}
function openCookieSettings () {
  if ('Optanon' in window) {
    window.Optanon.ToggleInfoDisplay();
  }
}
onMounted(() => {
    /* Our observer composable is used here (onIntersect) with 
        onEnter as the callback when intersecting and 
        onExit as the callback when no longer intersecting.
        {threshold: 0.8} is the IntersectionObserver Options
    */
    if (scrollRef.value) {
        observer.value = onIntersect(scrollRef.value, onEnter);
    }
});

</script>
