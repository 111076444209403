import AEM from 'base/js/aem';
import { ScrollSpy } from 'bootstrap';

class AnchorBar extends AEM.Component {
    init() {
        // ScrollSpy functionality
        const TOP = 60;
        const SPACE = 32;
        const ENTER = 13;
        const anchorBar = this.element.querySelector('.menu-container');
        const anchorBarOffset = anchorBar.offsetHeight + TOP;
        /* eslint-disable no-unused-vars */
        const scrollSpy = new ScrollSpy(document.body, {
            target: '[data-component="anchor-bar"]',
            offset: anchorBarOffset
        });
        /* eslint-enable no-unused-vars */

        // Dropdown menu functionality
        const anchorTitle = this.element.querySelector('.navbar-anchor-bar');
        const anchorNav = this.element.querySelector('.anchor-bar-menu');
        const anchorLink = this.element.querySelectorAll('a.anchor-bar-link');
        function handleToggle() {
            anchorTitle.classList.toggle('active');
            anchorNav.classList.toggle('active');
        }

        anchorTitle.addEventListener('click', handleToggle);
        anchorTitle.addEventListener('keyup', event => {
            if (event.keyCode === ENTER || event.keyCode === SPACE) {
                handleToggle();
            }
        });

        anchorLink.forEach(item => {
            item.addEventListener('click', handleToggle);
        });
    }
}

export { AnchorBar };
