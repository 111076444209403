<template>
	<div class="col" v-for="location in locationsData" :key="location.elements.officeName">
		<article class="card mb-3 location-card">
			<div class="card-body">
				<h3 class="card-title">{{ location.elements.officeName.value }}</h3>
				<div class="card-text" v-html="location.elements.officeAddress.value"></div>
			</div>
		</article>
	</div>
</template>

<script setup>
import { inject } from 'vue';
const locationsData = JSON.parse(inject('locations')).items.sort((p1, p2) =>
		Number.parseInt(p1.elements.order.value, 10) - Number.parseInt(p2.elements.order.value, 10));

</script>
