import AEM from 'base/js/aem';
import SearchUtils from 'base/js/templates/search/searchUtils';

class SearchBox extends AEM.Component {
    async init() {
        await customElements.whenDefined('atomic-search-interface');
        const searchInterface = this.element.querySelector(
            'atomic-search-interface'
        );

        let searchInterfaceOptions = {
            coveoAccessToken : this.props.accessToken,
            coveoOrganizationId : this.props.orgId,
            pipelineContext : this.props.pipelineContext,
            translations : this.props.translations
        };

        await SearchUtils.initializeSearchInterface(searchInterface, searchInterfaceOptions);

        const engine = searchInterface.engine;
        if (this.props.pipelineContext) {
            await SearchUtils.setContext(engine, this.props.pipelineContext);
        }

        this.element.querySelector('atomic-search-box').addEventListener('redirect', e => {
            e.preventDefault();
            const { redirectTo, value } = e.detail;
            const path = `${redirectTo}#q=${value}`;
            window.location.href = path;
        });
    }
}

export { SearchBox };
