import { Modal } from 'bootstrap';
import { createApp } from 'vue';
import YouTube from 'Components/embed/embeddable/youtube/clientlibs/js/Youtube.vue';
import Brightcove from 'Components/embed/embeddable/brightcove/clientlibs/js/Brightcove.vue';

function showModal(element, textCmpVidId, isBrightcoveLink, videoURL, textCmpBcAccountId) {
    const videoOptions = {
        videoId: element.dataset.bsYtVideoId ? element.dataset.bsYtVideoId : !isBrightcoveLink ? textCmpVidId : '',
        playLabel: element.dataset.bsYtPlayLabel,
        loop: element.dataset.bsYtLoop,
        inline: element.dataset.bsYtInline,
        brightcoveAccountId: element.dataset.brightcoveAccountId ? element.dataset.brightcoveAccountId : isBrightcoveLink ? textCmpBcAccountId: '',
        brightcoveVideoId: element.dataset.brightcoveVideoId ? element.dataset.brightcoveVideoId : isBrightcoveLink ? textCmpVidId : '',
        brightcoveVideoUrl: isBrightcoveLink ? videoURL : '',
        brightcoveLoop: element.dataset.brightcoveLoop,
        brightcoveInline: element.dataset.brightcoveInline,
        brightcoveBgUrl: element.dataset.brightcoveBgUrl,
        modalType: element.dataset.modalType
    };

    // Build modal
    let modalElement = document.getElementById('youtube-modal');
    let enableBrightcove = videoOptions.modalType === 'brightcove' || isBrightcoveLink ? true : false;
    if (enableBrightcove) {
        modalElement = document.getElementById('brightcove-modal');
    }
    if (!modalElement) {
        let videoTemplate = document.querySelector('#youtube-embed-modal');
        if (videoOptions.brightcoveVideoUrl || videoOptions.modalType === 'brightcove') {
            videoTemplate = document.querySelector('#brightcove-embed-modal');
        }
        const modalContent = videoTemplate.content.cloneNode(true);
        const bs5Element = document.querySelector('.amd-bs5');
        bs5Element.appendChild(modalContent);
    }
    let videoModal;
    let videoModalElement;
    let placeHolder;
    let videoEmbedElement ;
    if (enableBrightcove) {
        videoModal = new Modal(document.getElementById('brightcove-modal'));
        videoModalElement = document.querySelector('#brightcove-modal');
        placeHolder = videoModalElement.querySelector('[data-function="brightcove-video-placeholder"]');
        videoEmbedElement = videoModalElement.querySelector('.bc-embed');
    } else {
        videoModal = new Modal(document.getElementById('youtube-modal'));
        videoModalElement = document.querySelector('#youtube-modal');
        placeHolder = videoModalElement.querySelector('[data-function="youtube-video-placeholder"]');
        videoEmbedElement = videoModalElement.querySelector('.yt-embed');
    }

    applyVideoAttr(placeHolder, videoEmbedElement, videoModalElement, videoOptions, enableBrightcove);

    const videoComponent = enableBrightcove ? createApp(Brightcove) : createApp(YouTube);
    if (enableBrightcove) {
        videoComponent.provide('accountId', videoOptions.brightcoveAccountId);
        videoComponent.provide('videoId', videoOptions.brightcoveVideoId);
        videoComponent.provide('videoOptions', buildBcVideoOptoins(videoOptions));
        videoComponent.provide('videoElement', placeHolder);
    } else {
        videoComponent.provide('videoUrl', buildVideoSrc(videoOptions));
        videoComponent.provide('directVideoUrl', `https://www.youtube.com/watch?v=${ videoOptions.videoId }`);
    }
    videoComponent.provide('cookieLabel', videoEmbedElement.dataset.cmpCookieLabel);
    videoComponent.provide('playLabel', videoOptions.playLabel);
    videoComponent.provide('cookieSettingsButton', videoEmbedElement.dataset.cmpCookieSettingsButton);
    videoComponent.provide('watchOnYouTube', videoEmbedElement.dataset.cmpWatchOnYoutube);

    videoComponent.mount(placeHolder);

    videoModal.show();
    videoModalElement.addEventListener('shown.bs.modal', showCookieSettings);
    videoModalElement.addEventListener('hidden.bs.modal', () => {
        let bcVideoPlayer = placeHolder.querySelector('.video-js');
        if (bcVideoPlayer) {
            bcVideoPlayer.remove();
        }
        videoComponent.unmount();
    });
}
function applyVideoAttr(placeHolderEl, embedElement, videoElem, options, enableBrightcove) {
    let bgImage = '';
    if (enableBrightcove) {
        bgImage = `url(${ options.brightcoveBgUrl })`;
    } else {
        bgImage = `url("https://i.ytimg.com/vi/${ options.videoId }/hqdefault.jpg")`;
    }
    let playText = 'Play';
    if (options.playLabel) {
        playText = options.playLabel;
    }

    placeHolderEl.style.backgroundImage = bgImage;
    if (enableBrightcove) {
        embedElement.setAttribute('data-cmp-video-id', options.brightcoveVideoId);
        embedElement.setAttribute('data-cmp-video-url', options.brightcoveVideoUrl);
    } else {
        embedElement.setAttribute('data-cmp-video-id', options.videoId);
        embedElement.setAttribute('data-cmp-video-url', buildVideoSrc(options));
    }
    embedElement.setAttribute('data-cmp-play-label', playText);
}
function buildVideoSrc(options) {
    const origin = `origin=http%3A%2F%2F${ location.host.replace(':', '%3A')}`;
    const pathArray = location.pathname.split('/');
    let language = pathArray[1];
    let enableLoop = '&loop=0';
    let enableInline = '&playsinline=0';
    if (pathArray[1] === 'content' || pathArray[1] === 'editor.html') {
        language = pathArray[4];
    }
    if (options.loop === 'true') {
        enableLoop = `&loop=1&playlist=${ options.videoId }`;
    }
    if (options.inline === 'true') {
        enableInline = '&playsinline=1';
    }
    return `https://www.youtube.com/embed/${ options.videoId }?${ origin }&hl=${ language }&mute=0&autoplay=1${ enableLoop }${ enableInline }&enablejsapi=1&rel=0`;
}
function richTextYtLinks(links) {
    // YouTube modal links.
    links.forEach(item => {
        const isModal = item.querySelector('.yt-modal-link');
        if (isModal) {
            item.addEventListener('click', e => {
                e.preventDefault();
                const videoUrl = item.getAttribute('href');
                const isBrightcoveLink = videoUrl.includes('players.brightcove.net');
                const regexPattern = /^(https:\/\/[^.]*\.youtube\.com\/watch\?v=|https:\/\/[^.]*\.youtube\.com\/embed\/|https:\/\/youtu\.be\/)/;
                const bcVideoIdRegex = /videoId=(\d+)/;
                let linkVideoId = videoUrl.replace(regexPattern, '');
                let bcAccountId;
                if (isBrightcoveLink) {
                    const match = videoUrl.match(bcVideoIdRegex);
                    let urlSegment = videoUrl.split('/');
                    bcAccountId = urlSegment[3];
                    if (match) {
                        linkVideoId= match[1];
                    }
                }
                showModal(item, linkVideoId, isBrightcoveLink, videoUrl, bcAccountId);
            });
        }
    });
}
function showCookieSettings (){
    let cookieLink = document.querySelector('.modal-body .ot-sdk-show-settings');
    if (cookieLink) {
        cookieLink.addEventListener('click', e => {
            let messageBackground = document.querySelector('.onetrust-pc-dark-filter');
            let settingsPrompt = document.querySelector('#onetrust-pc-sdk');
            if (messageBackground.classList.contains('ot-hide')) {
                messageBackground.classList.remove('ot-hide');
            }
            if (settingsPrompt.classList.contains('ot-hide')) {
                settingsPrompt.classList.remove('ot-hide');
            }
            messageBackground.removeAttribute('style');
            settingsPrompt.removeAttribute('style');
            settingsPrompt.style.height = "80%";
        });
    }
}
function checkThumbnailSize(linkElement) {
    return new Promise((resolve, reject) => {
        const videoID = linkElement.dataset.bsYtVideoId;
        const maxResUrl = `https://i.ytimg.com/vi/${ videoID }/maxresdefault.jpg`;
        const hqResUrl = `https://i.ytimg.com/vi/${ videoID }/hqdefault.jpg`;
        const img = new Image();
        img.src = maxResUrl;
        img.onload = () => {
            if (img.naturalHeight >= 360 && img.naturalWidth >= 480){
                resolve(maxResUrl);
            } else {
                resolve(hqResUrl);
            }
        };
        img.onerror = () => {
            reject(hqResUrl);
        };
    }).catch(() => {
        return hqResUrl;
    });
}
function buildBcVideoOptoins(options) {
    let bcVideoOptions = {autoplay:"any"};
    if (options.brightcoveLoop === 'true') {
        bcVideoOptions.loop = true;
    }
    if (options.brightcoveInline === 'true') {
        bcVideoOptions.playsinline = true;
    }
    return bcVideoOptions;
}
export { showModal, richTextYtLinks, checkThumbnailSize };
