import AEM from 'base/js/aem';
import { richTextYtLinks } from 'base/js/templates/general/youtubeModal.js';

class Text extends AEM.Component {
    init() {
        function dropShadow(wrapper, tableItem) {
            let scrollLeft = Math.ceil(wrapper.scrollLeft);
            let tableWidth = tableItem.offsetWidth;
            let containerWidth = wrapper.offsetWidth;
            let scrollRight = tableWidth - scrollLeft - containerWidth;
            if (tableWidth === containerWidth) {
                wrapper.classList.remove('shadow-right', 'shadow-left', 'shadow-both');
            } else if (scrollRight <= 0) {
                wrapper.classList.remove('shadow-right', 'shadow-both');
                wrapper.classList.add('shadow-left');
            } else if (scrollLeft <= 0) {
                wrapper.classList.remove('shadow-left', 'shadow-both');
                wrapper.classList.add('shadow-right');
            } else if (scrollLeft > 0 && scrollRight > 0) {
                wrapper.classList.remove('shadow-right', 'shadow-left');
                wrapper.classList.add('shadow-both');
            }
        }

        // Make all tables responsive.
        const tableList = this.element.querySelectorAll('table');
        tableList.forEach(tableItem => {
            let outerWrapper = document.createElement('div');
            outerWrapper.className = 'table-wrapper';
            let wrapper = document.createElement('div');
            wrapper.className = 'table-responsive';
            tableItem.parentNode.insertBefore(outerWrapper, tableItem);
            tableItem.parentNode.insertBefore(wrapper, tableItem);
            wrapper.appendChild(tableItem);
            outerWrapper.appendChild(wrapper);
            wrapper.addEventListener('scroll', () => {
                dropShadow(wrapper, tableItem);
            });
            window.addEventListener('resize', () => {
                dropShadow(wrapper, tableItem);
            });
            dropShadow(wrapper, tableItem);
        });

        // YouTube modal links.
        const links = this.element.querySelectorAll('a');
        richTextYtLinks(links);
    }
}

export { Text };
