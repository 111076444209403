<template>

        <template v-if="consent">
            <template v-if="!showVideo">
                <button type="button" ref="scrollRef" class="lty-playbtn" @click="show">
                    <span class="lyt-visually-hidden">
                        {{ playLabel }}
                    </span>
                </button>
            </template>
        </template>
        <div v-else class="bc-ot-consent">
            <p v-html="cookieLabel"></p>
        </div>
</template>
<script setup>

import { inject, ref, onMounted, watch } from 'vue'
import { consentCheck, PERFORMANCE, TARGETING, FUNCTIONAL } from 'base/js/templates/general/consentCheck.js';
import { onIntersect } from 'base/js/templates/general/onIntersect.js';
import brightcovePlayerLoader from '@brightcove/player-loader';

const consent = ref(false);
const getConsent = () => consent.value = consentCheck([PERFORMANCE, TARGETING, FUNCTIONAL]);
getConsent();
window.addEventListener('cookiesUpdated', getConsent);
let showVideo = ref(false);

const playLabel = inject('playLabel');
const cookieLabel = inject('cookieLabel');
const videoOptions = inject('videoOptions');
const videoId = inject('videoId');
const accountId = inject('accountId');
const videdoElement = inject('videoElement');

const observer = ref(null);
const scrollRef = ref(null);
const onEnter = () => {
    show();
    
}
function show() {
    showVideo.value = true;
}

watch(showVideo, (newValue) => {
    if (newValue == true) {
        // Call the brightcovePlayerLoader API
        brightcovePlayerLoader({
            refNode: videdoElement,
            accountId: accountId,
            playerId: 'default',
            embedId: 'default',
            videoId: videoId,
            options: videoOptions
        });
    }
});
onMounted(() => {
    /* Our observer composable is used here (onIntersect) with 
        onEnter as the callback when intersecting and 
        onExit as the callback when no longer intersecting.
        {threshold: 0.8} is the IntersectionObserver Options
    */
    if (scrollRef.value) {
        observer.value = onIntersect(scrollRef.value, onEnter);
    }
});

</script>
