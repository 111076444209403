import AEM from 'base/js/aem';

class ComparisonChart extends AEM.Component {
    init() {
        this.initAnimateChart();
        this.checkIfSingleBar();
    }
    initAnimateChart() {
        let chartWrapperList = this.element.querySelectorAll('.chart-animate');
        const options = {
            rootMargin: '0px',
            threshold: 1.0
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    let barsList = entry.target.querySelectorAll('.chart-group .chart-item .score, .chart-wrapper.chart-vertical .chart-item-wrapper');
                    barsList.forEach(barScore => {
                        if (barScore && !barScore.classList.contains('vertical-bar')) {
                            barScore.style['margin-left'] = 0;
                        } else if (barScore && barScore.classList.contains('vertical-bar')) {
                            barScore.style.height = '100%';
                        }
                    });
                }
            });
        }, options);

        chartWrapperList.forEach(chartWrapper => {
            if (chartWrapper) {
                observer.observe(chartWrapper);
            }
        });
    }

    checkIfSingleBar() {
        let chartWrapperList = this.element.querySelectorAll('.chart-group.single-bar');
        chartWrapperList.forEach(chartWrapper => {
            let firstBar = chartWrapper.querySelector('.chart-item .score:first-child');
            if (firstBar !== null) {
                firstBar.classList.add('first-bar');
                let barsList = chartWrapper.querySelectorAll('.chart-item .score');
                barsList.forEach(bar => {
                    if (!bar.classList.contains('first-bar')) {
                        bar.style.position = 'absolute';
                        bar.style.top = 0;
                    }
                });
            }
        });
    }
}

export { ComparisonChart };
