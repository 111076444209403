import AEM from 'base/js/aem';

class ImageComparison extends AEM.Component {
    init() {
        const imageCompareContainer = this.element.querySelector('.image-comparison-container');
        const sliderElement = this.element.querySelector('.image-compare-slider');
        sliderElement.addEventListener('input', e => {
            imageCompareContainer.style.setProperty('--position', `${ e.target.value }%`);
            sliderElement.style.cursor = 'ew-resize';
        });
    }
}

export { ImageComparison };
