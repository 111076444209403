import { getCookie} from "./consentCheck";

function getCountry() {

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	if (urlParams.has('region')) {
		const regionParam = urlParams.get('region');
		return regionParam;
	} else {
		if (document.cookie.includes('pmuser_country')) {
			return getCookie('pmuser_country');
		} else {
			return 'us';
		}
	}
}

export { getCountry }
