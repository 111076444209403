export function initModalMovement(modalElements) {
    modalElements.forEach(modalElement => {
        const originalParent = modalElement.parentElement;


        const moveModalToRoot = () => {
            document.body.appendChild(modalElement);
        };


        const moveModalBack = () => {
            originalParent.appendChild(modalElement);
        };


        modalElement.addEventListener('show.bs.modal', moveModalToRoot);
        modalElement.addEventListener('hidden.bs.modal', moveModalBack);
    });
}





