import { pushToGoogleDataLayer } from '../general/googleAnalytics';

function coveoPlusReplace(string) {
    if (string) {
        return string.replace(/\+/g, 'xplusx');
    }
    return string;
}

function coveoPlusRestore(string) {
    if (string) {
        return string.replace(/xplusx/g, '+');
    }
    return string;
}

async function initializeSearch(searchInterface, searchInterfaceOptions, isSecureSearch) {
    let coveoAccessToken = searchInterfaceOptions.coveoAccessToken;
    let coveoOrganizationId = searchInterfaceOptions.coveoOrganizationId;
    let pipelineContext = searchInterfaceOptions.pipelineContext;
    let translations = searchInterfaceOptions.translations;

    /* Coveo Configs*/
    const coveoSettings = {
        accessToken: coveoAccessToken,
        organizationId: coveoOrganizationId,
        organizationEndpoints: await searchInterface.getOrganizationEndpoints(coveoOrganizationId),
        analytics: {
            anonymous: true,
            analyticsClientMiddleware: (eventType, payload) => {
                if (eventType === 'search' && payload.responseTime) {
                    const totalCount = searchInterface.engine.state.search.response.totalCount;
	                payload.customData.totalCount = totalCount;
	                pushToGoogleDataLayer(`coveo-${eventType}`, 'coveoAnalyticsEventData', payload);
                }

                return payload;
            },
        },
        preprocessRequest: (request, clientOrigin) => {
            if (clientOrigin === 'searchApiFetch') {
                const body = JSON.parse(request.body);
                if (body.hasOwnProperty('facets')) {
                    body.facets.forEach(facet => {
                        facet.currentValues.forEach(facetValue => {
                            facetValue.value = coveoPlusReplace(facetValue.value);
                            return facetValue;
                        });
                    });
                }

                request.body = JSON.stringify(body);
            }

            return request;
        },

        search: {
            preprocessSearchResponseMiddleware: response => {
                response.body.facets.forEach(facet => {
                    facet.values.forEach(facetValue => {
                        facetValue.value = coveoPlusRestore(facetValue.value);
                        return facetValue;
                    });
                });
                return response;
            },
            preprocessFacetSearchResponseMiddleware: response => {
                response.body.values.forEach(facetValue => {
                    facetValue.displayValue = coveoPlusRestore(facetValue.displayValue);
                    facetValue.rawValue = coveoPlusRestore(facetValue.rawValue);
                    return facetValue;
                });
                return response;
            },
            preprocessQuerySuggestResponseMiddleware: response => response,
        },
    };


    /* Generate the token + renew the expired token*/
    if (isSecureSearch) {
        const generateToken = async isNonRenewFlag => {
            try {
                const tokenData = {
                    token: coveoAccessToken
                };

                const lsTest = () => {
                    const test = 'test';
                    try {
                        sessionStorage.setItem(test, test);
                        sessionStorage.removeItem(test);
                        return true;
                    } catch (e) {
                        return false;
                    }
                };

                const storageIsSupported = lsTest();

                // store in session storage else store in window object
                if (!(storageIsSupported && sessionStorage.getItem('coveoSecureToken')) || !isNonRenewFlag) {
                    let origin = window.location.origin.replace('www.amd.com', 'account.amd.com');
                    const response = await fetch(`${origin}/bin/protected/amd/searchtoken`, { credentials: 'include' });
                    if (response.ok) {
                        let data = await response.json();
                        if (data.hasOwnProperty('token')) {
                            if (storageIsSupported) {
                                sessionStorage.setItem('coveoSecureToken', data.token);
                            } else {
                                window.coveoSecureToken = data.token;
                            }

                            return Promise.resolve(data.token);
                        }
                        throw new Error('JSON response does not have token attribute');
                    } else {
                        throw new Error('Error generating JSON response');
                    }
                } else {
                    if (storageIsSupported && sessionStorage.getItem('coveoSecureToken') !== null) {
                        return Promise.resolve(sessionStorage.getItem('coveoSecureToken'));
                    }
                    return Promise.resolve(window.coveoSecureToken);
                }
            } catch (error) {
                return Promise.resolve(coveoAccessToken);
            }
        };

        const coveoToken = await generateToken(true);
        coveoSettings.accessToken = coveoToken;
        coveoSettings.renewAccessToken = generateToken;
    }


    await searchInterface.initialize(coveoSettings);

    // Disable the coveo analytics if performance cookies are blocked
    let { consentCheck, PERFORMANCE } = await import('../general/consentCheck.js');
    let getConsent = consentCheck([ PERFORMANCE ]);
    if (!getConsent) {
        searchInterface.analytics = false;
    }

    // Add i18n for coveo search captions (facets)
    if (pipelineContext && translations) {
        const map = new Map(Object.entries(JSON.parse(translations)));
        for (let [ key, value ] of map) {
            searchInterface.i18n.addResourceBundle(pipelineContext, key, value);
        }
    }
}

/* Public Search */
async function initializeSearchInterface(searchInterface, searchInterfaceOptions) {
    await initializeSearch(searchInterface, searchInterfaceOptions, false);
}

/* Secure Search */
async function initializeSecureSearchInterface(searchInterface, searchInterfaceOptions) {
    await initializeSearch(searchInterface, searchInterfaceOptions, true);
}


/*
	setContext -> Sets the query context.
*/
async function setContext(engine, contextValue) {
    if (contextValue) {
        let { loadContextActions } = await import(/* webpackChunkName: 'coveoheadless' */
            '@coveo/headless');
        const action = loadContextActions(engine).addContext({
            contextKey: 'amd_lang',
            contextValue: contextValue
        });
        engine.dispatch(action);
    }
}

/*
	setAdvancedQueryExpression -> Initializes the advanced query query parameter.
	Important: Use this for registering the advanced query the first time and it doesnt expose in the url hashparameter
*/
async function setAdvancedQueryExpression(engine, expression) {
    if (expression) {
        let { loadAdvancedSearchQueryActions } = await import(/* webpackChunkName: 'coveoheadless' */
            '@coveo/headless');
        const action = loadAdvancedSearchQueryActions(
            engine
        ).registerAdvancedSearchQueries({
            cq: expression
        });
        engine.dispatch(action);
    }
}

/*
	updateAdvancedQueryExpression -> Updates the advanced query query parameter.
	Important: Use this for updating the advanced query and it exposes the values in the url hashparameter
*/
async function updateAdvancedQueryExpression(engine, expression) {
    if (expression) {
        let { loadAdvancedSearchQueryActions } = await import(/* webpackChunkName: 'coveoheadless' */
            '@coveo/headless');
        const action = loadAdvancedSearchQueryActions(
            engine
        ).updateAdvancedSearchQueries({
            cq: expression
        });
        engine.dispatch(action);
    }
}


/*
	setFieldSort -> Initializes the sortCriteria query parameter.
	Important: Use this for registering the sort criteria the first time and it doesnt expose in the url hashparameter
*/
async function setFieldSort(engine, sortField, sortOrder) {
    if (sortField) {
        let { loadSortCriteriaActions } = await import('@coveo/headless');
        const action = loadSortCriteriaActions(
            engine
        ).registerSortCriterion(
            [ { by: 'field', field: sortField, order: sortOrder } ]
        );

        engine.dispatch(action);
    }
}

/*
	updateFieldSort -> Updates the sortCriteria query parameter.
	Important: Use this for updating the sort criteria and it exposes the values in the url hashparameter
*/
async function updateFieldSort(engine, sortField, sortOrder) {
    if (sortField) {
        let { loadSortCriteriaActions } = await import('@coveo/headless');
        const action = loadSortCriteriaActions(
            engine
        ).updateSortCriterion(
            [ { by: 'field', field: sortField, order: sortOrder } ]
        );

        engine.dispatch(action);
    }
}

/*
	setNumberOfResults -> Initializes the `numberOfResults` query parameter.
	Important: Use this for registering the numberOfResults criteria the first time and it doesnt expose in the url hashparameter
*/
async function setNumberOfResults(engine, numberOfResults) {
    if (numberOfResults) {
        let { loadPaginationActions } = await import('@coveo/headless');
        const action = loadPaginationActions(
            engine
        ).registerNumberOfResults(
            Number(numberOfResults)
        );

        engine.dispatch(action);
    }
}

/*
	updateNumberOfResults -> Updates the `numberOfResults` query parameter
	Important: Use this for updating the numberOfResults criteria and it exposes the values in the url hashparameter
*/
async function updateNumberOfResults(engine, numberOfResults) {
    if (numberOfResults) {
        let { loadPaginationActions } = await import('@coveo/headless');
        const action = loadPaginationActions(
            engine
        ).updateNumberOfResults(
            Number(numberOfResults)
        );

        engine.dispatch(action);
    }
}

export default {
    initializeSecureSearchInterface: initializeSecureSearchInterface,
    initializeSearchInterface: initializeSearchInterface,
    setAdvancedQueryExpression: setAdvancedQueryExpression,
    updateAdvancedQueryExpression: updateAdvancedQueryExpression,
    setContext: setContext,
    setFieldSort: setFieldSort,
    updateFieldSort: updateFieldSort,
    setNumberOfResults: setNumberOfResults,
    updateNumberOfResults: updateNumberOfResults
};
