import AEM from 'base/js/aem';

class Navigation extends AEM.Component {
    init() {
        const currentURL = window.location.pathname.split('?')[0];
        const navLinks = this.element.querySelectorAll('.cmp-navigation__item-link');

        navLinks.forEach(link => {
            if (link.getAttribute('href') === currentURL) {
                link.classList.add('active');
                let parentNavGroup = link.closest('.navigation-group.collapse');
                let grandParentNavGroup = link.closest('.navigation-group-level-0');
                if (parentNavGroup) {
                    const parentLink = this.element.querySelector(`a[href="#${parentNavGroup.id}"]`);
                    parentLink.setAttribute('aria-expanded', 'true');
                    parentNavGroup.classList.add('show');
                    if (grandParentNavGroup) {
                        grandParentNavGroup.classList.add('show');
                    }
                }
            }
        });
    }
}

export { Navigation };
