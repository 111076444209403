import AEM from 'base/js/aem';

class Footer extends AEM.Component {
    init() {
        const mainLinkContainer = this.element.querySelector('#footer-main-link');
        const linkGroups = mainLinkContainer.querySelectorAll('.cmp-container');
        linkGroups.forEach(group => {
            const groupHeader = group.querySelector('.cmp-title__link');
            const text = group.querySelector('.text');
            if (text) {
                const groupHeaderLinks = text.querySelectorAll('a');
                if (groupHeader) {
                    const groupTitle = groupHeader.textContent.trim();
                    groupHeader.dataset.trackingInformation = groupTitle;
                    groupHeaderLinks.forEach(link => {
                        link.dataset.trackingInformation = `${groupTitle}|${link.textContent.trim()}`;
                    });
                }
                groupHeader.role = 'button';
            }
        });
    }
} export { Footer };
