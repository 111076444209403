import AEM from 'base/js/aem';
import { inView, animate } from 'motion';
class Container extends AEM.Component {
    init() {
        this.initContainer();
    }

    initContainer() {
        const el = this.element;
        const video = el.querySelector('.cmp-container__video')?.querySelector('video');
        const MOBILE = 768;
        const TABLET = 992;
        const isMobile = window.innerWidth < MOBILE;
        const isTablet = window.innerWidth < TABLET;

        if (video) {
            const disableVideo = video.dataset.showVideo === 'disabled';
            if (isMobile) {
                video.poster = video.dataset.mobilePoster;
            } else if (isTablet) {
                video.poster = video.dataset.tabletPoster;
            }

            if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches && !(isMobile && disableVideo)) {
                video.querySelectorAll('source').forEach(src => {
                    if (src.dataset.src && src.dataset.src !== '') {
                        src.src = src.dataset.src;
                    }
                });
                video.load();

                this.initScrollToPlay(video);

                const videoButton = video.nextElementSibling;
                if (videoButton && videoButton.dataset.initialized === undefined) {
                    const buttonClasses = videoButton.classList;
                    videoButton.dataset.initialized = 'initialized';
                    videoButton.addEventListener('click', () => {
                        if (buttonClasses.contains('fa-play')) {
                            video.play();
                            buttonClasses.add('fa-pause');
                            buttonClasses.remove('fa-play');
                        } else {
                            video.pause();
                            buttonClasses.add('fa-play');
                            buttonClasses.remove('fa-pause');
                        }
                    });
                }
            }
        }
        this.animateContainer();
    }
    animateContainer() {
        const classes = this.element.parentElement.classList;
        const animateClasses = [ 'slide-left', 'slide-right', 'slide-up', 'slide-down', 'fade-in' ];
        const matches = classes && animateClasses.some(item => classes.contains(item));
        function isTouchPointer() {
            return matchMedia('(pointer: coarse)').matches;
        }
        if (matches && !isTouchPointer()) {
            inView(this.element.parentElement, ({ target }) => {
                animate(
                    this.element,
                    { opacity: 1, transform: 'none' },
                    { delay: 0.2, duration: 0.9, easing: [ 0.17, 0.55, 0.55, 1 ] }
                );
            },
            { amount: 0.5 });
        }
    }
    initScrollToPlay(video) {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    video.currentTime = 0; // Reset video to start
                    video.play();
                } else {
                    video.pause();
                }
            });
        }, { threshold: 0.5 }); // Adjust threshold as needed

        observer.observe(video);
    }

    moveImage(video) {
        const image = video.querySelector('picture');
        const parent = video.parentElement;
        if (image) {
            parent.appendChild(image);
            video.remove();
        }
    }
}

export { Container };
