import AEM from 'base/js/aem';
import { checkThumbnailSize } from 'base/js/templates/general/youtubeModal.js';

class youtubeImage extends AEM.Component {
    init() {
        let linkElement = this.element.querySelector('.cmp-image__link');
        let youtubeImageEl = this.element.querySelector('.yt-thumbnail');
        let playButton = this.element.querySelector('.yt-play-button');
        if (linkElement) {
            let ytVideoId = linkElement.dataset.bsYtVideoId;
            let bcVideoId = linkElement.dataset.brightcoveVideoId;
            let bcBackgroundImg = linkElement.dataset.brightcoveBgUrl;
            if (bcVideoId && youtubeImageEl) {
                youtubeImageEl.src = bcBackgroundImg;
                youtubeImageEl.removeAttribute('style');
                playButton.removeAttribute('style');
            } else if (ytVideoId && youtubeImageEl) {
                checkThumbnailSize(linkElement).then(validUrl => {
                    youtubeImageEl.src = validUrl;
                    youtubeImageEl.removeAttribute('style');
                    playButton.removeAttribute('style');
                });
            }
        }
    }
}

export { youtubeImage };
