/**
 * Load external js asynchronously.
 * @param {string} src  JavaScript file to be loaded.
 * @param {boolean} async Optional parameter to set or disable the async
 */
const loadScript = ((src, async = true) => {
	const scriptUrls = Array.isArray(src) ? src : [src];

	return Promise.all(scriptUrls.map((url) => {
		return new Promise((resolve, reject) => {
			try {
				let js = document.createElement('script');
				let head = document.getElementsByTagName('head')[0] || document.documentElement;
				let isDone = false;
				js.src = url;
				js.type = 'text/javascript';
				js.async = async;

				/**
				 * Handle Script loading error.
				 */

				js.onerror = ((error) => {
					reject(error, js);
				});

				/**
				 * Handle Script loading success.
				 */

				js.onload = js.onreadystatechange = (() => {

					if (!isDone && (!js.readyState || (js.readyState === 'complete' || js.readyState === 'loaded' && js.nextSibling !== null))) {
						isDone = true;
						if (isDone) {
							resolve();
						}
						// Remove listeners.
						js.onload = js.onreadystatechange = null;

					} else {
						if (js.readyState === 'loaded' && js.nextSibling === null) {
							throw new Error('Unable to load script: ' + js.src);
						}
					}
				});

				head.appendChild(js);
			} catch (err) {
				reject(err);
			}
		});
	}));
});

export { loadScript };