import AEM from 'base/js/aem';

class Tabs extends AEM.Component {
    init() {
        const vertical = this.element.closest('.layout-vertical');
        const tabs = this.element.closest('.tabs');
        if (tabs && !tabs.classList.contains('layout-vertical')) {
            tabs.classList.add('layout-horizontal');
        }
        this.element.querySelectorAll('li[role="tab"]').forEach(link => {
            link.addEventListener('click', e => {
                if (e.target.id.length > 0) {
                    history.replaceState(undefined, undefined, `#${ e.target.id}`);
                }
            });
            if (vertical) {
                const linkHeight = link.offsetHeight;
                link.style.setProperty('--height', `${linkHeight }px`);
            }
        });
    }
}

export { Tabs };
