import AEM from 'base/js/aem';
import { Modal } from 'bootstrap';
import { consentCheck, getCookie, PERFORMANCE, TARGETING, FUNCTIONAL } from 'base/js/templates/general/consentCheck.js';
import { onIntersect } from 'base/js/templates/general/onIntersect.js';

class CustomModal extends AEM.Component {
    init() {
        this.initModal();
    }
    initModal() {
        const modalElem = this.element.querySelector('.modal');
        const modalId = modalElem.getAttribute('id');
        const trigger = modalElem.querySelector('.modal-dialog').getAttribute('modal-trigger');
        const modalContainer = new Modal(modalElem);
        const enableCookie = modalElem.querySelector('.modal-dialog').getAttribute('enable-cookie');
        const cookieExpiration = parseInt(modalElem.querySelector('.modal-dialog').getAttribute('cookie-expiration'), 10);
        const hashValue = window.location.hash.slice(1);
        let targetElement;

        if (hashValue) {
            targetElement = document.querySelector(`#${hashValue}`);
        }

        if (modalId){
            const params = new URLSearchParams(window.location.search);
            const modalIdParam = params.has('modal') ? params.get('modal') : '';

            if (modalIdParam !== '' && modalIdParam === modalId) {
                window.setTimeout(() => this.showModal(modalId, modalContainer, enableCookie, cookieExpiration), 100);
            }

            if (trigger === 'exit') {
                document.addEventListener('mouseleave', event => {
                    if (event.clientY <= 0) {
                        this.showModal(modalId, modalContainer, enableCookie, cookieExpiration);
                    }
                }, { once: true });
            } else if (trigger === 'delay') {
                if (window.location.hash) {
                    const delayObserver = onIntersect(targetElement, () => window.setTimeout(
                        () => modalContainer.show(), 2000),
                    () => {
                        // Opens Modal if scrolling to anchor element stops prematurely
                        window.setTimeout(() => this.showModal(modalId, modalContainer, enableCookie, cookieExpiration), 4000);
                        delayObserver.unobserve(targetElement);
                    },
                    true,
                    { threshold: 0.5 });
                } else {
                    window.setTimeout(() => this.showModal(modalId, modalContainer, enableCookie, cookieExpiration), 2000);
                }
            } else if (trigger === 'scroll') {
                let scrollPercentage = modalElem.querySelector('.modal-dialog').getAttribute('scroll-percentage');
                let percentage = () => scrollPercentage ? parseInt(scrollPercentage, 10) : 50;
                let shown = false;
                const showOnScroll = () => {
                    if (window.scrollY >= document.body.scrollHeight * percentage() / 100) {
                        shown = true;
                        this.showModal(modalId, modalContainer, enableCookie, cookieExpiration);
                        document.removeEventListener('scroll', showOnScroll);
                    }
                };

                if (window.location.hash) {
                    const scrollObserver = onIntersect(targetElement, () => window.setTimeout(() => {
                        showOnScroll();
                        if (!shown) {
                            document.addEventListener('scroll', showOnScroll);
                        }
                    }, 1000),
                    () => {
                        // Add event listener if scrolling to anchor element stops prematurely
                        window.setTimeout(() => document.addEventListener('scroll', showOnScroll), 1000);
                        scrollObserver.unobserve(targetElement);
                    },
                    true,
                    { threshold: 0.5 });
                } else {
                    window.setTimeout(showOnScroll, 500);
                    document.addEventListener('scroll', showOnScroll);
                }
            }
        }

        modalElem.addEventListener('hide.bs.modal', () => {
            if (window.location.hash.includes(modalId)) {
                window.history.pushState('', document.title, window.location.pathname + window.location.search);
            }
        });

        window.addEventListener('hashchange', () => {
            if (window.location.hash.substring(1) === modalId) {
                window.setTimeout(() => modalContainer.show(), 100);
            }
        });
    }

    setCookie(name, value, expire) {
        let expires = '';
        if (expire) {
            const date = new Date();
            date.setTime(date.getTime() + expire * 24 * 60 * 60 * 1000);
            expires = `expires=${ date.toUTCString() }`;
        }
        document.cookie = `${ name }=${ value };${ expires };path=/`;
    }

    showModal(id, modal, cookie, expire) {
        let cookieName = `modal-${ id}`;
        let modalOpened = false;
        const consent = { value: false };
        const getConsent = () => {
            consent.value = consentCheck([ PERFORMANCE, TARGETING, FUNCTIONAL ]);
        };

        if (!getCookie(cookieName)) {
            if (cookie === 'true') {
                getConsent();
                window.addEventListener('cookiesUpdated', () => {
                    getConsent();
                    if (consent.value === true && !getCookie(cookieName) && modalOpened) {
                        this.setCookie(cookieName, id, expire);
                    }
                });
                if (consent.value === true) {
                    this.setCookie(cookieName, id, expire);
                }
            }
            modal.show();
            modalOpened = true;
        }
    }
}

export { CustomModal };
