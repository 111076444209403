const languageMap = new Map();
const simpleLanguages = ['en', 'de', 'fr', 'es', 'pt', 'ko', 'ja'];
simpleLanguages.forEach(language => languageMap.set(language, language));
languageMap.set('zh-CN', 'zh-hans');
languageMap.set('zh-TW', 'zh-hant');

function mapLanguage() {
    const pageLanguage = document.documentElement.getAttribute('lang');
    return languageMap.get(pageLanguage);
}

export { mapLanguage };