<template>
	<div class="shopping-cart-dropdown">
		<a id="shoppingCart" aria-expanded="false" aria-label="Shopping Cart" class="dropdown-toggle"
			 data-bs-auto-close="outside"
			 data-bs-toggle="dropdown" data-tracking-information="shoppingCart" href="#" role="button"
			 :data-content="quantity">
			<i class="fass fa-cart-shopping"><span v-if="products.length > 0 || bcProducts > 0" class="cart-dot"/></i>

		</a>
		<div aria-labelledby="shoppingCart" class="dropdown-menu dropdown-menu-end">
			<div class="dropdown-menu-content">
				<div class="shopping-cart-container">
					<div class="cartheading-container">
						<span> {{ labels.title }} </span>
						<button v-on:click="(event) => closeMenu(event,true)">
							<i class="fass fa-xmark"></i>
						</button>
					</div>
					<div class="cartbody-container">
						<div class="product-container">
							<div class="row product-item" v-if="bcProducts > 0">
								<div class="col-4 col-lg-3 info-col">
									<div class="img-wrapper">
										<img alt="Product Image" :src="images.bc"/>
									</div>
								</div>
								<div class="col-8 info-col">
									<h6>{{ labels.bcProducts}} {{ labels.itemsInCart }}</h6>
									<div class="add-remove">
										<label for="quantity">{{ labels.labelQty }}</label>
										<input name="quantity"
													 type="number"
													 disabled
													 :value="bcProducts"
										/>
									</div>
									</div>
								<div class="row">
									<div class="col-12">
										<a class="btn" :href="bcLink">{{ labels.bcProducts }} {{ labels.checkout }}</a>
									</div>
								</div>
							</div>
							<div class="row product-item" v-if="products.length > 0">
								<div class="col-4 col-lg-3 info-col">
									<div class="img-wrapper">
										<img alt="Product Image" :src="images.oms" />
									</div>
								</div>
								<div class="col-8 info-col">
									<h6>{{ labels.omsProducts }} {{ labels.itemsInCart }}</h6>
									<div class="add-remove">
										<label for="quantity">{{ labels.labelQty }}</label>
										<input name="quantity"
													 type="number"
													 disabled
													 :value="omsQuantity"
										/>
									</div>
								</div>
								<div class="col-12">
									<form
											id="oms-cart"
											:action="checkoutPath"
											method="get">
										<input name="command" type="hidden" value="ONLINESTORE"/>
										<button class="btn submit" type="submit"
														:disabled="isLoading">
											{{ labels.omsProducts }} {{ labels.checkout }}
										</button>
									</form>
								</div>
							</div>
							<div class="row" v-if="products.length < 1 && bcProducts < 1">
								<div class="col info-col text-center">
									<div class="img-wrapper">
										<img alt="Empty cart" :src="images.empty" />
									</div>
									<h3>{{ labels.emptyTitle }}</h3>
									<p>{{ labels.emptyText1 }}</p>
								</div>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	</div>
</template>

<script setup>
import { computed, inject, ref, onMounted, onBeforeMount } from 'vue';
import { getCookie } from 'base/js/templates/general/consentCheck.js';
import { Dropdown } from 'bootstrap';
const cookieName = inject('cookieName');
const cookiePath = inject('cookiePath');
const checkoutPath = inject('checkoutPath');
const server = inject('server');
const images = inject('images');
const labels = inject('labels');
const isLoading = ref(true);
const errorMessages = ref([]);
const products = ref([]);
const omsQuantity = computed(() => calQuantity(products.value));
const bcProducts = ref(0);
const bcLink = ref('');
const omsSid = ref(getCookie(cookieName));
const bcStatus = ref({});
const cookieSynced = ref(false);

onBeforeMount(async () => {
	getOMSStatus();
	getBCStatus();
});
function getBCStatus () {
	let products, link;
	if (getCookie('bc-products-in-cart') !== null && !isNaN(parseInt(getCookie('bc-products-in-cart')))) {
		products = parseInt(getCookie('bc-products-in-cart'));
		link = getCookie('bc-url');
	}
	else if (sessionStorage.getItem('itemsInCart') !== null && !isNaN(parseInt(sessionStorage.getItem('itemsInCart')))) {
			products = parseInt(sessionStorage.getItem('itemsInCart'));
			link = sessionStorage.getItem(('url'));
	}
	else {
		return false;
	}
	bcProducts.value = products;
	bcLink.value = link;
	return products > 0 ? true : false;
}

function setCookie(data) {
	let expire = new Date(new Date().getTime() + data.sid[1] * 1000);
  const cookieDomain = window.location.hostname.substring((window.location.hostname.indexOf('.')));
  document.cookie = `oms_sid=${omsSid.value}; expires=${expire.toUTCString()}${cookiePath};SameSite=None; Secure; Domain=${cookieDomain}`;
}

function getOMSStatus () {
	if (getCookie(cookieName) !== '') {
		loadCart();
	}
	else if (sessionStorage.getItem(cookieName) !== null) {
		omsSid.value = sessionStorage.getItem(cookieName);
    if (omsSid.value !== '') {
      const expire = new Date();
      expire.setDate(expire.getDate() + 6);
      const cookieDomain = window.location.hostname.substring((window.location.hostname.indexOf('.')));
      document.cookie = `oms_sid=${omsSid.value}; expires=${expire.toUTCString()}${cookiePath};SameSite=None; Secure; Domain=${cookieDomain}`;

    }
		loadCart();
	}
	else {
		return false;
	}
}

/*Get the list of all products from OMS*/
function loadCart(callback) {
	let options = {
		oms_sid: omsSid.value
	};
	let path = '/eCommerce/product/load';
	fetchProducts(path, 'GET', options).then(data => {
		omsSid.value = data.sid[0];
		setCookie(data);
	}).then(()=>{
		if(callback){
			callback();
		}
	}).catch((err) => console.log(err));
}

/* Calculate the Total Quantity of all items in the cart*/
const calQuantity = (arrayInput) => arrayInput.reduce(
		(accumulator, value) => {
			let quantity = parseInt(value.quantity, 10);
			return accumulator + quantity;
		}, 0);

function closeMenu(event, closeFlag) {
	const shoppingCartElement = document.querySelector('.dropdown-toggle#shoppingCart');

	if (shoppingCartElement) {
		const shoppingCartDropdown = Dropdown.getOrCreateInstance(shoppingCartElement);
		if (shoppingCartDropdown) {
			if (closeFlag) {
				if (shoppingCartElement.classList.contains('show')) {
					shoppingCartDropdown.hide();
				}
			} else {
				if (!shoppingCartElement.classList.contains('show')) {
					shoppingCartDropdown.show();
				}
			}
		}
	}
}

/*Returns a Promise of FETCH API results*/
function fetchProducts(path, method, options) {
	const fetchOptions = {
		method: `${method}`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
		}
	};
	let url = server + path;
	let bodyObj = `${Object.keys(options).map(key => `${key}=${options[key]}`).join('&')}`;
	if (method.toLowerCase() === 'get') {
		url += `?${bodyObj}`;
	} else {
		fetchOptions.body = bodyObj;
	}
	let jsonError = '';
	isLoading.value = true;
	errorMessages.value = [];
	const promise = new Promise((resolve, reject) => {
		fetch(url, fetchOptions)
				.then(response => response.json())
				.then(data => {
					isLoading.value = false;
					if (data.error) {
						jsonError = data.error;
						throw new Error(`Error adding new Product`);
					}

					if (data && data.products) {
						products.value = data.products;
					}
					errorMessages.value = [];
					resolve(data);
				})
				.catch(error => {
					isLoading.value = false;
					if (jsonError) {
						errorMessages.value.push((JSON.stringify(jsonError)));
					} else {
						errorMessages.value.push((error.message));
					}
					reject(error);
				});
	});

	// eslint-disable-next-line consistent-return
	return promise;
}

function updateQuantity(event, qty, itmId, callback) {
	const quantity = (!isNaN(qty)) ? qty : event.target.value;
	const itemId = itmId ? itmId : event.target.closest('.product-item').dataset.itemId;

	if (quantity < 0) {
		errorMessages.value.push('Quantity must be > 0');
		return;
	}

	if (isNaN(quantity)) {
		errorMessages.value.push('Quantity must be a number');
		return;
	}

	let options = {
		oms_sid: omsSid.value,
		itemId: itemId,
		quantity: quantity
	}

	let path = '/eCommerce/product/update';
	if (quantity === 0) {
		path = '/eCommerce/product/delete';
	}

	fetchProducts(path, 'POST', options).then(()=>{
		if(callback){
			callback();
		}
	}).catch((err) => console.log(err));
}

function addProductBy(sku, callback) {
	let options = {
		oms_sid: omsSid.value,
		sku: sku
	}

	let path = '/eCommerce/product/add';
	fetchProducts(path, 'POST', options).then(()=>{
		if(callback){
			callback();
		}
	}).catch((err) => console.log(err));
}

const getProductBySku = (arrayInput, sku) => arrayInput.filter((value)=> {
	if (value.sku.trim() == sku.trim()) {
		return true;
	}
	return false;
}, 0);

onMounted(() => {
	document.querySelectorAll('.add-to-cart').forEach((value) => {
		value.addEventListener('click', event => {
			const targetElement = event.target.closest('.add-to-cart');
			if (targetElement) {
				event.stopPropagation();
				function loadCartCallback(){
					let sku = targetElement.getAttribute('sku');
					if (sku) {
						let product = getProductBySku(products.value, sku);
						if (product && product.length > 0) {
							const skuProduct = {...product[0]};
							updateQuantity(targetElement, skuProduct.quantity + 1, skuProduct.itemId, closeMenu);
						} else {
							addProductBy(sku, closeMenu);
						}
					}
				}
				loadCart(loadCartCallback);
			}
		});
	});
});


</script>
